<template>
  <div>
    <Card :bordered="false" dis-hover class="ivu-mt">
      <div class="new_card_pd">
        <Tabs v-model="currentTab" @on-click="handleChangeTab">
          <TabPane :label="item.label" :name="item.value.toString()" v-for="(item, index) in headerList" :key="index" />
        </Tabs>
      </div>
      <Row class="content">
        <Col span="16">
          <WangEditor style="width: 100%" :content="formValidate.content" @editorContent="getEditorContent"></WangEditor>
        </Col>
        <Col span="6" style="width: 33%">
          <div class="ifam">
            <div class="content" v-html="content"></div>
          </div>
        </Col>
      </Row>
    </Card>
  </div>
</template>

<script>
import {
  getAgreementsApi
} from '@/api/system'

export default {
  name: 'index',
  mixins: [],
  data () {
    return {
      currentTab: '1',
      headerList: [
        { label: '付费会员协议', value: '1' },
        { label: '代理商协议', value: '2' },
        { label: '隐私协议', value: '3' },
        { label: '用户协议', value: '4' },
        { label: '注销协议', value: '5' },
        { label: '积分协议', value: '6' }
      ],

      ueConfig: {
        autoHeightEnabled: false,
        initialFrameHeight: 500,
        initialFrameWidth: '100%',
        UEDITOR_HOME_URL: '/UEditor/',
        serverUrl: ''
      },

      id: 0,
      formValidate: {
        content: ''
      },
      content: ''
    }
  },
  components: {},
  // 支柱道具属性
  props: {},
  // 计算属性 类似于 data 概念
  computed: {},
  // 监控 data 中的数据变化
  watch: {},
  // 生命周期 - 创建完成（可以访问当前this 实例）
  created () {
  },
  // 生命周期 - 挂载完成（可以访问 DOM 元素）
  mounted () {
    this.handleChangeTab(this.currentTab)
  },
  // 方法集合
  methods: {
    handleChangeTab (data) {
      this.formValidate.content = ' '
      getAgreementsApi(data).then((res) => {
        this.formValidate.id = res.data.id || 0
        this.formValidate.type = res.data.type
        this.formValidate.title = res.data.title
        this.formValidate.content = res.data.content
        this.content = res.data.content
      })
    },
    // 获取富文本内容
    getEditorContent (content) {
      this.content = content
    }
  },
  // 生命周期 - 更新之前
  beforeUpdate () {
  },
  // 生命周期 - 更新之后
  updated () {
  },
  // 过滤器
  filters: {},
  // 生命周期 - 创建之前
  beforeCreate () {
  },
  // 生命周期 - 挂载之前
  beforeMount () {
  },
  // 生命周期 - 销毁之前
  beforeDestroy () {
  },
  // 生命周期 - 销毁完成
  destroyed () {
  },
  // 如果页面有 keep-alive 缓存功能,这个函数会触发
  // 进入的时候触发
  activated () {
  },
  // 离开的时候触发
  deactivated () {
  }
}
</script>

<style scoped lang="scss">
.ifam {
  width: 344px;
  height: 644px;
  background: url('../../../assets/images/ag-phone.png') no-repeat center top;
  background-size: 344px 644px;
  padding: 40px 20px;
  padding-top: 50px;
  margin: 0 auto 0 20px;

  .content {
    height: 560px;
    overflow: hidden;
    scrollbar-width: none; /* firefox */
    -ms-overflow-style: none; /* IE 10+ */
    overflow-x: hidden;
    overflow-y: auto;
  }

  .content::-webkit-scrollbar {
    display: none; /* Chrome Safari */
  }
}
</style>
